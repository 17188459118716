import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { forTabletLandscapeUp } from "../../tokens/media-queries";

type BackgroundProps = {
  size?: "small" | "default" | "large";
  variant?: "blue" | "black" | "red";
};

export const FAQBackground = styled.div.attrs<BackgroundProps>((props) => ({
  "data-size": props.size || "default",
  "data-variant": props.variant || "blue"
}))<BackgroundProps>`
  --faq-color: #fff;
  --faq-background-color: #0033ad;
  --faq-background-size: ${pxToRem(395)};

  color: var(--faq-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--faq-background-color);
  background-size: var(--faq-background-size);
  background-repeat: no-repeat;
  background-position: calc(100% + var(--faq-background-size) / 3)
    calc(100% + var(--faq-background-size) / 3);

  padding: var(--spacing-large);
  padding-right: ${pxToRem(60)};
  position: relative;
  border-radius: ${pxToRem(15)};

  &[data-size="large"] {
    padding: ${pxToRem(40)};
  }
  &[data-size="default"] {
    min-height: ${pxToRem(215)};
  }
  &[data-size="small"] {
    --faq-background-size: ${pxToRem(138)};
    width: ${pxToRem(100)};
    height: ${pxToRem(100)};
  }
  @media ${forTabletLandscapeUp} {
    &[data-size="large"] {
      --faq-background-size: ${pxToRem(791)};

      min-height: ${pxToRem(415)};
      padding: ${pxToRem(80)};
      padding-right: 33%;
      font-size: ${pxToRem(26)};
      line-height: 1.538;
    }
    &[data-variant="blue"] {
      background-image: url("/cards/faq-bg-blue.png");
    }
    &[data-variant="red"] {
      --faq-background-color: #ff5654;
      background-image: url("/cards/faq-bg-red.png");
    }
    &[data-variant="black"] {
      --faq-background-color: #1d1d1b;
      background-image: url("/cards/faq-bg-black.png");
    }
  }
  & a[href] {
    color: inherit;
  }
`;

export const FAQTitle = styled.h1`
  color: var(--faq-color);
  font-weight: 400;
  margin: 0;
  @media ${forTabletLandscapeUp} {
    font-size: ${pxToRem(32)};
    line-height: 1.375;
  }
`;

export const FAQHeading = styled.h2`
  color: var(--faq-color);
  font-weight: 400;
  font-size: ${pxToRem(22)};
  line-height: 1.5454;
  margin: 0;
`;
