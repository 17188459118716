export var InteractionType;
(function (InteractionType) {
    InteractionType["UPVOTE"] = "https://schema.org/LikeAction";
    InteractionType["DOWNVOTE"] = "https://schema.org/DislikeAction";
})(InteractionType || (InteractionType = {}));
export var ReactActionType;
(function (ReactActionType) {
    ReactActionType["UPVOTE"] = "LikeAction";
    ReactActionType["DOWNVOTE"] = "DislikeAction";
})(ReactActionType || (ReactActionType = {}));
export function toInteractionType(type) {
    return `https://schema.org/${type}`;
}
