import Tooltip from "@reach/tooltip";
import styled from "styled-components";
import { InlineSeparator } from "./InlineSeparator";
import { Avatar } from "../avatar/Avatar";
import { AvatarPlaceholder } from "../avatar/AvatarPlaceholder";
import DateTimeFormat from "../date-time/DateTime";
import Text from "../text/Text";
import VerifiedLabel from "../verified-label/VerifiedLabel";
import { Item } from "../../data/db/types";

type Props = {
  item: Item;
  level?: "1" | "2";
};

export const AvatarContainer = styled.span`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  & > * + * {
    margin-left: var(--spacing-default);
  }
`;

export const PosterInformationElement = styled.div`
  --poster-information-gap: var(--spacing-default);
  color: var(--link-color);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > * + * {
    margin-left: var(--poster-information-gap);
  }
`;

export function PosterInformation({ item, level = "2" }: Props) {
  const author = item.author ? item.author[0] : undefined;
  const date = item.dateCreated;
  const showSeparators = level === "1";
  return (
    <PosterInformationElement data-poster-info>
      <AvatarContainer>
        {author?.image ? (
          <Avatar level={level} src={author.image} />
        ) : (
          <AvatarPlaceholder level={level} />
        )}
        {author && author._verifiedContributor ? (
          <Tooltip label="A 'Verified Contributor' is an official member of the IOG organisation.">
            <VerifiedLabel>{author.name}</VerifiedLabel>
          </Tooltip>
        ) : (
          <Text level="2">{author?.name}</Text>
        )}
      </AvatarContainer>
      {showSeparators && <InlineSeparator data-poster-separator />}
      <Text isMuted level="2" data-article-date>
        <DateTimeFormat
          value={date}
          options={DateTimeFormat.options.ddmmyyyy}
        />
      </Text>
    </PosterInformationElement>
  );
}
