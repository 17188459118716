import * as React from "react";

function Comment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-icon
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.333 17.5V6.667a2.5 2.5 0 012.5-2.5h8.334a2.5 2.5 0 012.5 2.5v5a2.5 2.5 0 01-2.5 2.5h-7.5L3.334 17.5zM6.667 7.5h6.666M6.667 10.833h5"
        stroke="currentColor"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Comment;
