import type { SWRConfiguration } from "swr";
import type { Item, ItemComment } from "../data/db/types";
import { useSWR } from "./useSWR";

type Shape = {
  item: Item | null;
  comments: ItemComment[] | null;
};

export default function useItem(
  slug: string,
  options: SWRConfiguration<Shape> = {}
) {
  const apiUri = `/api/item/${slug}`;
  const {
    data,
    mutate,
    isValidating,
    error: _error
  } = useSWR<Shape>(apiUri, {
    ...options
  });

  const item = data && "item" in data ? data.item : options.fallbackData?.item;
  const comments =
    (data && "comments" in data
      ? data.comments
      : options.fallbackData?.comments) || null;
  const error = data && "error" in data ? data.error : _error;

  return {
    item,
    comments,
    error,
    isValidating,
    mutate
  };
}
