import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { forTabletLandscapeUp } from "../../tokens/media-queries";

type BackgroundProps = {
  size?: "small" | "default" | "large";
  variant?: "a" | "b" | "c";
};

export const GlossaryBackground = styled.div.attrs<BackgroundProps>(
  (props) => ({
    "data-size": props.size || "default",
    "data-variant": props.variant || "a"
  })
)<BackgroundProps>`
  --glossary-color: var(--default-text-color);
  --glossary-bg-color: #f3f6ff;
  --glossary-bg-width: ${pxToRem(652)};
  --glossary-bg-height: ${pxToRem(395)};
  --glossary-bg-image: url("/cards/glossary-bg-1.png");
  --glossary-bg-position-x: calc(100% + var(--glossary-bg-width) / 2);
  --glossary-bg-position-y: calc(100% + var(--glossary-bg-height) / 2);

  color: var(--glossary-color);
  background-image: var(--glossary-bg-image);
  background-color: var(--glossary-bg-color);
  background-size: var(--glossary-bg-width) var(--glossary-bg-height);
  background-position: var(--glossary-bg-position-x)
    var(--glossary-bg-position-y);
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: var(--spacing-large);
  padding-right: ${pxToRem(60)};
  position: relative;
  border-radius: ${pxToRem(15)};

  &[data-size="large"] {
    padding: ${pxToRem(40)};
  }
  &[data-size="default"] {
    min-height: ${pxToRem(215)};
  }
  &[data-size="small"] {
    --glossary-bg-width: ${pxToRem(227)};
    --glossary-bg-height: ${pxToRem(138)};
    width: ${pxToRem(100)};
    height: ${pxToRem(100)};
  }
  @media ${forTabletLandscapeUp} {
    &[data-size="large"] {
      --glossary-bg-width: ${pxToRem(1304)};
      --glossary-bg-height: ${pxToRem(791)};

      min-height: ${pxToRem(415)};
      padding: ${pxToRem(80)};
      padding-right: 33%;
      font-size: ${pxToRem(26)};
      line-height: 1.538;
    }
    &[data-variant="a"] {
    }
    &[data-variant="b"] {
      --glossary-bg-color: #f5faff;
      --glossary-bg-image: url("/cards/glossary-bg-2.png");
      --glossary-bg-position-y: calc(0% - var(--glossary-bg-height) / 2);
    }
    &[data-variant="c"] {
      --glossary-bg-color: #f9faff;
      --glossary-bg-image: url("/cards/glossary-bg-3.png");
      --glossary-bg-position-y: 50%;
    }
  }
  & a[href] {
    color: inherit;
  }
`;

export const GlossaryTitle = styled.h1`
  color: var(--glossary-color);
  font-weight: 400;
  margin: 0;
  @media ${forTabletLandscapeUp} {
    font-size: ${pxToRem(32)};
    line-height: 1.375;
  }
`;

export const GlossaryHeading = styled.h2`
  color: var(--glossary-color);
  font-weight: 400;
  font-size: ${pxToRem(26)};
  line-height: 1.5454;
  margin: 0;
`;
