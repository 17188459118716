import { Svg, SvgProps } from "./Svg";

function Thumb(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.833 7.167v6.666a.833.833 0 01-.833.834H2.333a.833.833 0 01-.833-.834V8a.833.833 0 01.833-.833h2.5zm0 0a3.333 3.333 0 003.334-3.334V3A1.667 1.667 0 1111.5 3v4.167H14a1.667 1.667 0 011.667 1.666L14.833 13c-.12.511-.347.95-.648 1.25-.3.301-.658.447-1.018.417H7.333a2.5 2.5 0 01-2.5-2.5"
        stroke="currentColor"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default Thumb;
