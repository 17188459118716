export function getBoolean(
  envVar: string | undefined,
  fallback?: boolean
): boolean {
  if (typeof envVar === "string") {
    return ["yes", "true", "1"].includes(envVar);
  } else if (typeof fallback === "boolean") {
    return fallback;
  } else {
    throw new Error(`Missing environment variable: ${envVar}`);
  }
}

export function getInt(envVar: string | undefined, fallback?: number): number {
  let parsed = NaN;
  if (typeof envVar === "string") {
    parsed = parseInt(envVar, 10);
  }

  if (Number.isSafeInteger(parsed)) {
    return parsed;
  } else if (typeof fallback === "number") {
    return fallback;
  } else {
    throw new Error(`Missing environment variable: ${envVar}`);
  }
}

export function getString(
  envVar: string | undefined,
  fallback?: string
): string {
  if (typeof envVar === "string") {
    return envVar;
  } else if (typeof fallback === "string") {
    return fallback;
  } else {
    throw new Error(`Missing environment variable: ${envVar}`);
  }
}
