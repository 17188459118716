import React from "react";
import styled from "styled-components";
import { I18nContextType, useI18nContext } from "../../context/I18nContext";

const DateTimeElement = styled.time`
  white-space: nowrap;
`;

function getFormatter(
  ctx: I18nContextType,
  options: Intl.DateTimeFormatOptions,
  cacheKey?: string
) {
  if (cacheKey && ctx.cache[cacheKey]) {
    return ctx.cache[cacheKey] as Intl.DateTimeFormat;
  }
  const formatter = Intl.DateTimeFormat(ctx.locale, options);
  if (cacheKey) {
    ctx.cache[cacheKey] = formatter;
  }
  return formatter;
}

type Props = {
  options?: Intl.DateTimeFormatOptions;
  cacheKeyFn?: (locale: string, options: Intl.DateTimeFormatOptions) => string;
  value: Date | number | string | undefined;
};

type Formats = {
  ddmmyyyy: Intl.DateTimeFormatOptions;
  default: Intl.DateTimeFormatOptions;
  dateAndShortMonth: Intl.DateTimeFormatOptions;
  dateAndShortMonthAndYear: Intl.DateTimeFormatOptions;
  minimalMonthAndYear: Intl.DateTimeFormatOptions;
};

const defaultCacheKeyFn = (
  locale: string,
  options: Intl.DateTimeFormatOptions
) => locale + JSON.stringify(options);

const DateTimeFormat: React.FC<
  Props & React.ComponentPropsWithoutRef<typeof DateTimeElement>
> & { options: Formats } = ({
  value,
  options = DateTimeFormat.options.default,
  cacheKeyFn = defaultCacheKeyFn,
  ...props
}) => {
  const i18nCtx = useI18nContext();
  if (!value) {
    return null;
  }
  const cacheKey = cacheKeyFn(i18nCtx.locale || "en", options);
  const d = new Date(value);
  const formatter = getFormatter(i18nCtx, options, cacheKey);
  return (
    <DateTimeElement dateTime={d.toISOString()} data-date-time {...props}>
      {formatter.format(d)}
    </DateTimeElement>
  );
};

DateTimeFormat.options = {
  ddmmyyyy: {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  },
  default: {
    day: "numeric",
    month: "long",
    year: "numeric"
  },
  dateAndShortMonth: {
    day: "numeric",
    month: "short"
  },
  dateAndShortMonthAndYear: {
    day: "numeric",
    month: "short",
    year: "numeric"
  },
  minimalMonthAndYear: {
    day: undefined,
    month: "long",
    year: "numeric"
  }
};

export default DateTimeFormat;
