import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import VerifiedLabel from "../verified-label/VerifiedLabel";

export type iVerifiedContentRibbon = {
  appearance: "ribbon-left" | "ribbon-top";
};

export const VerifiedContentLabel = styled(VerifiedLabel)`
  border-radius: 0 ${pxToRem(19, 19)} 0;
  padding: ${pxToRem(6, 15, 6)} var(--spacing-xsmall);
  &:hover {
    background: var(--verified-content-hover-color);
  }
`;
VerifiedContentLabel.defaultProps = {
  children: "Verified Content"
};

const Container = styled.div`
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: var(--verified-content-font-size);
  line-height: 1;

  &:before,
  &:after {
    position: absolute;
    top: 100%;
    display: block;
    content: "";
    height: ${pxToRem(9)};
  }

  &:before {
    width: calc(100% - ${pxToRem(12 + 19)});
    left: ${pxToRem(12)};
    background: linear-gradient(
      177deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 44.44%
    );
  }
  &:after {
    width: ${pxToRem(12)};
    background: linear-gradient(
      var(--verified-content-fold-background-gradient)
    );
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }

  &[data-appearance="ribbon-top"] {
    transform: rotate(90deg);
    padding: 0;

    &:before {
      background: linear-gradient(
        167deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 44.44%
      );
    }

    [data-icon] {
      transform: rotate(-90deg);
    }
    [data-verified-label] {
      padding: ${pxToRem(6)} var(--spacing-small) ${pxToRem(6, 30)};
    }
    [data-verified-content] {
      display: none;
    }
  }
`;

const VerifiedContentRibbon: React.FC<iVerifiedContentRibbon> = ({
  appearance = "ribbon-left",
  children
}) => (
  <Container data-verified-ribbon data-appearance={appearance}>
    <VerifiedContentLabel>{children}</VerifiedContentLabel>
  </Container>
);

export default VerifiedContentRibbon;
