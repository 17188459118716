import React from "react";
import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import colors from "../../tokens/colors";
import Heading from "../heading/Heading";
import External from "../icons/External";
import { Svg, SvgProps } from "../icons/Svg";

function PlayIcon(props: SvgProps) {
  return (
    <Svg
      data-play-icon
      width={64}
      height={64}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={32} cy={32} r={32} fill="currentColor" />
      <path d="M51 32L22.5 48.455v-32.91L51 32z" fill="#fff" />
    </Svg>
  );
}

const DummyEmbedElement = styled.a`
  position: relative;
  display: block;
  background-color: ${colors.secondary.londonFog};
  color: ${colors.primary.cardanoBlue};
  text-decoration: none;
  padding: var(--spacing-large);
  border-radius: ${pxToRem(10)};
  ${Heading} {
    margin: 0 0 var(--spacing-large);
  }
  & > [data-icon] {
    position: absolute;
    top: var(--spacing-default);
    right: var(--spacing-default);
  }
  &[data-size="small"] {
    & h4 {
      font-size: ${pxToRem(14)};
      line-height: 1.1;
    }
    & [data-play-icon] {
      width: ${pxToRem(32)};
      height: ${pxToRem(32)};
    }
    & [data-icon] {
      width: ${pxToRem(16)};
      height: ${pxToRem(16)};
    }
  }
`;

const PlayAndProgress = styled.div`
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: var(--spacing-large);
  }
`;

const ProgressBar = styled.div`
  flex-grow: 1;
  position: relative;
  background: ${colors.secondary.lilacCloud};
  border-radius: ${pxToRem(10)};
  height: ${pxToRem(10)};

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    background-color: currentColor;
    border-radius: 50%;
    transform: translateY(-50%);
  }

  [data-size="small"] & {
    height: ${pxToRem(5)};
    border-radius: ${pxToRem(5)};
    &::after {
      width: ${pxToRem(10)};
      height: ${pxToRem(10)};
    }
  }
`;

type Props = {
  title: string;
  href: string;
  size?: "default" | "small";
};
const DummyEmbed: React.FC<Props> = ({ title, href, size = "default" }) => {
  return (
    <DummyEmbedElement
      href={href}
      target="_blank"
      rel="noopener"
      data-size={size}
    >
      <External />
      <Heading level="4">{title}</Heading>
      <PlayAndProgress>
        <PlayIcon />
        <ProgressBar />
      </PlayAndProgress>
    </DummyEmbedElement>
  );
};

export default DummyEmbed;
