import { getBoolean, getString } from "./config-utils";

export const NEXT_PUBLIC_YOUTUBE_API_KEY = getString(
  // we have to directly access the var so next.js can see it
  // this won't work:
  // const envVarName = "NEXT_PUBLIC_YOUTUBE_API_KEY"
  // console.log(process.env[envVarName])
  process.env.NEXT_PUBLIC_YOUTUBE_API_KEY
);

export const NEXT_PUBLIC_ENABLE_COMMENTS = getBoolean(
  process.env.NEXT_PUBLIC_ENABLE_COMMENTS,
  false
);
