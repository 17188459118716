import * as React from "react";

function Verified(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-icon
      width={14}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.664 1.022a.4.4 0 01.672 0l1.128 1.75a.4.4 0 00.454.165l1.988-.616a.4.4 0 01.516.433l-.262 2.065a.4.4 0 00.242.419l1.92.806a.4.4 0 01.116.662L11.911 8.12a.4.4 0 00-.084.477l.951 1.85a.4.4 0 01-.336.583l-2.079.102a.4.4 0 00-.37.31l-.461 2.03a.4.4 0 01-.632.23l-1.658-1.258a.4.4 0 00-.484 0L5.1 13.702a.4.4 0 01-.632-.23l-.46-2.03a.4.4 0 00-.371-.31l-2.079-.102a.4.4 0 01-.336-.582l.951-1.851a.4.4 0 00-.084-.477L.562 6.706a.4.4 0 01.117-.662l1.919-.806a.4.4 0 00.242-.42l-.262-2.064a.4.4 0 01.516-.433l1.988.616a.4.4 0 00.454-.165l1.128-1.75z"
        fill="#fff"
      />
      <path
        d="M4.667 8.666l1.166 1.167 3.5-4.667"
        stroke="#002891"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Verified;
