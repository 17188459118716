import * as React from "react";
import { Svg, SvgProps } from "./Svg";

/**
 * This should be the canonical
 * "down arrow" used for dropdowns
 */
function CaretIcon(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={11}
      height={7}
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 1l4.5 5L10 1"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default CaretIcon;
