import { ReactActionType } from "@input-output-hk/essential-cardano-models";
import styled from "styled-components";
import { VisuallyHidden } from "../a11y/VisuallyHidden";
import { trueOrUndef } from "../../utils/dom-attribute-helpers";
import Thumb from "../icons/Thumb";
import { pxToRem } from "@input-output-hk/px-to-rem";

const Count = styled.span`
  margin-left: var(--spacing-small);
`;

const VoteWrapper = styled.div`
  --vote-button-gap: var(--spacing-default);
  color: var(--theme-color-primary);
  align-items: center;
  display: inline-flex;
  font-size: var(--text-level2-size);
  line-height: 1;
`;
const VoteButton = styled.button`
  font-family: inherit;
  background: transparent;
  border: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  touch-action: manipulation;
  color: inherit;
  &:active {
    outline: ${pxToRem(2)} solid var(--vote-focus-color);
  }
  &[data-up-vote][data-active] {
    color: var(--vote-up-active-color);
  }
  &[data-down-vote][data-active] {
    color: var(--vote-down-active-color);
  }
  &:hover {
    color: var(--theme-color-secondary);
  }
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
  & + & {
    margin-left: var(--vote-button-gap);
  }
`;

const ThumbDown = styled(Thumb)`
  transform: rotate(180deg);
`;

type VoteProps = React.ComponentPropsWithoutRef<typeof VoteWrapper> & {
  myVote?: ReactActionType;
  allowUpvote?: boolean;
  allowDownvote?: boolean;
  totalCount?: number;
  upvoteCount?: number;
  downvoteCount?: number;
  disabled?: boolean;
  onClick: (type: ReactActionType) => void;
};

/**
 * TODO: Use tooltips? to convey user vote
 * state - i.e. You upvoted this!
 */

const Vote: React.FC<VoteProps> = ({
  myVote,
  upvoteCount,
  downvoteCount,
  onClick,
  disabled,
  appearance,
  ...rest
}) => (
  <VoteWrapper
    aria-live="polite"
    data-appearance={appearance}
    data-vote
    {...rest}
  >
    <VisuallyHidden>
      {upvoteCount} up votes and {downvoteCount} down votes. Total score{" "}
    </VisuallyHidden>
    <VoteButton
      onClick={() => onClick(ReactActionType.UPVOTE)}
      disabled={disabled}
      data-active={trueOrUndef(myVote === ReactActionType.UPVOTE)}
      data-up-vote
    >
      <Thumb />
      <VisuallyHidden>Upvote this</VisuallyHidden>
      <Count aria-hidden>{upvoteCount}</Count>
    </VoteButton>
    <VoteButton
      onClick={() => onClick(ReactActionType.DOWNVOTE)}
      disabled={disabled}
      data-active={trueOrUndef(myVote === ReactActionType.DOWNVOTE)}
      data-down-vote
    >
      <ThumbDown />
      <VisuallyHidden>Downvote this</VisuallyHidden>
      <Count aria-hidden>{downvoteCount}</Count>
    </VoteButton>
  </VoteWrapper>
);

export default Vote;
