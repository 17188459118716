import * as React from "react";
import { Svg, SvgProps } from "./Svg";

function External(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.407 9.407A3.667 3.667 0 018 8.333h6.666a1 1 0 110 2H8A1.667 1.667 0 006.333 12v12A1.667 1.667 0 008 25.667h12A1.667 1.667 0 0021.666 24v-6.667a1 1 0 012 0V24A3.667 3.667 0 0120 27.667H8A3.666 3.666 0 014.333 24V12c0-.973.386-1.905 1.074-2.593z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.373 4.626a1 1 0 010 1.414L14.04 19.374a1 1 0 01-1.414-1.414L25.959 4.626a1 1 0 011.414 0z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 5.333a1 1 0 011-1h6.667a1 1 0 011 1V12a1 1 0 11-2 0V6.333H20a1 1 0 01-1-1z"
        fill="currentColor"
      />
    </Svg>
  );
}

export default External;
