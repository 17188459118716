import { forwardRef } from "react";
import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import IconVerified from "../icons/Verified";

const Container = styled.div`
  background: var(--verified-label-background-color);
  color: var(--verified-label-text-color);
  font-size: var(--verified-label-text-size);
  border-radius: ${pxToRem(4)};
  padding: ${pxToRem(3.5)} ${pxToRem(8)};
  display: inline-flex;
  justifiy-content: center;
  align-items: center;
  &:hover {
    background: var(--verified-label-background-color-hover);
  }
`;

const Content = styled.span`
  padding-left: var(--spacing-small);
  line-height: ${pxToRem(12)};
  white-space: nowrap;
`;

const VerifiedLabel: React.FC<
  React.ComponentPropsWithoutRef<typeof Container>
  // eslint-disable-next-line react/display-name
> = forwardRef(({ children, ...props }, ref) => (
  <Container data-verified-label {...props} ref={ref}>
    <IconVerified />
    <Content data-verified-content>{children}</Content>
  </Container>
));

VerifiedLabel.displayName = "VerifiedLabel";

export default VerifiedLabel;
