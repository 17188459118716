import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { NEXT_PUBLIC_ENABLE_COMMENTS } from "../../public-config";
import IconComment from "../icons/Comment";
import { ContributeSmall } from "../icons/Contribute";

const IconAndCountLink = styled.a`
  align-items: center;
  display: inline-flex;
  font-size: var(--text-level2-size);
  line-height: 1;
  margin-left: var(--spacing-default);
  cursor: pointer;
  [data-icon] {
    margin-right: var(--spacing-xsmall);
  }
  color: var(--theme-color-primary);
  text-decoration: none;
  &:visited,
  &:active {
    color: var(--theme-color-primary);
  }
  &:hover {
    color: var(--theme-color-secondary);
  }
`;
export function CommentCountLink(props: { href: string; count: number }) {
  return NEXT_PUBLIC_ENABLE_COMMENTS ? (
    <Link href={`${props.href}#comments-section`} passHref>
      <IconAndCountLink aria-label="Article comments">
        <IconComment />
        {props.count}
      </IconAndCountLink>
    </Link>
  ) : null;
}

export function ContributeCountLink(props: { href: string; count: number }) {
  return (
    <Link href={`${props.href}#contributors`} passHref>
      <IconAndCountLink aria-label="Article contributors">
        <ContributeSmall />
        {props.count}
      </IconAndCountLink>
    </Link>
  );
}
