import { pxToRem } from "@input-output-hk/px-to-rem";
import styled from "styled-components";
import colors from "../../tokens/colors";

export const InlineSeparator = styled.span`
  display: inline-block;
  height: 1rem;
  line-height: 1rem;
  border-left: ${pxToRem(1)} solid ${colors.secondary.ashGrey};
`;
