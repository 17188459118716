import type { TImage } from "@input-output-hk/essential-cardano-models";

export type YouTubeImage = {
  url: string;
  width: number;
  height: number;
};

export function createYouTubeImage(youtubeUri: string) {
  const url = `https://img.youtube.com/vi/${new URL(
    youtubeUri
  ).searchParams.get("v")}/sddefault.jpg`;
  return {
    url,
    width: 640,
    height: 480
  };
}

export function isSupported(imageUrl: string) {
  return imageUrl.startsWith("http");
}

export function getPrefferedImageUrl(image: YouTubeImage | TImage): string {
  if ("_smartQualityUrl" in image && image._smartQualityUrl) {
    return image._smartQualityUrl;
  }

  return image.url;
}

export default isSupported;
