import _useSWR, { SWRConfiguration } from "swr";
import { ApiErrorResponse } from "../api-utils/errors";

const fetcher = (...args: Parameters<typeof fetch>) =>
  fetch(...args).then((res) => res.json());

export type MaybeApiError<T, E = string> = T | ApiErrorResponse<E>;

export const useSWR = <T extends any = any>(
  uri: string,
  options: SWRConfiguration = {}
) => {
  return _useSWR<MaybeApiError<T>>(uri, fetcher, options);
};
