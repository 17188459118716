import { ReactActionType } from "@input-output-hk/essential-cardano-models";
import { useCallback, useState } from "react";
import type { Action, Item } from "../data/db/types";
import { MaybeApiError } from "./useSWR";

/**
 *
 * @param itemId Can be undefined because the parent component
 * might not have loaded the item yet.
 */
export default function useVote(itemId: string | undefined) {
  const [isLoading, setIsLoading] = useState(false);
  const trigger = useCallback(
    async (action: ReactActionType | null, shouldDelete = false) => {
      setIsLoading(true);
      try {
        const res = await fetch("/api/action", {
          method: shouldDelete ? "DELETE" : "POST",
          body: JSON.stringify({
            object: itemId,
            action
          }),
          headers: {
            "Content-Type": "application/json"
          }
        });
        return res.json() as Promise<
          MaybeApiError<{
            action: Action;
            object: Item;
            agent: string;
          }>
        >;
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [itemId]
  );

  const vote = (action: ReactActionType) => trigger(action);
  const unvote = (action: ReactActionType) => trigger(action, true);

  return {
    vote,
    unvote,
    isLoading
  };
}
